

.App-logo-no-spin {
  pointer-events: none;
  height: 80%;
  max-height: 80px;
  pointer-events: auto; /* Ensure clicks are allowed */
  cursor: pointer; /* Show a pointer cursor */
}

.App-logo {
  pointer-events: none;
  height: 80%;
  max-height: 80px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
