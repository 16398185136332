
.title-text-404{
    color: #F7F7F8;
    font-size: 20px;
}

.title-icon-404{
    margin-top:20px;
    margin-bottom: 10%;
    width: 100px;
}