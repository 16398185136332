
  

.title-row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
}

.title-text{
    color: #F7F7F8;
    margin-left: 10px;
    font-size: 20px;
}

.title-icon{
    width: 50px;
}


.login-username{
    color:#F7F7F8;
    background-color: #2A2C2E;
    border-radius: 3px;
    margin-bottom: 20px;
    margin-top: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 40px;
    width: 296px; 
    outline: none;
    border: none;
    padding-left: 10px;
    box-sizing: border-box;
}

.password-row{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height:40px;
    margin-bottom: 20px;
}

.show-password{
    display: flex;
    width: 40px;
    height:40px;
    justify-content: center;
    align-items: center;
    margin-left: -40px;

}

.icon-show-password{
    width: 20px;
}

.login-password{
    color:#F7F7F8;
    background-color: #2A2C2E;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 40px;
    width: 296px; 
    outline: none;
    border: none;
    padding-left: 10px;
    box-sizing: border-box;
    padding-right: 40px;
}

.button-login{
    color:#F7F7F8;
    background-color: #2A2C2E;
    border-radius: 3px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 40px;
    width: 300px; 
    pointer-events: auto; /* Ensure clicks are allowed */
    cursor: pointer; /* Show a pointer cursor */
}

.error-text{
    width:100%;
    height:auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10%;
    padding-right: 10%;
    box-sizing: border-box;
    margin-top: 20px;
    color:#de5666;
    text-align: center;
    flex-direction: column;
}

.loading-wheel-contianer{
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mb-10{
    margin-bottom: 10px;
}