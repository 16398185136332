

#root{
    position: absolute;
    top:0px;
    left:0px;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .menu{
      width: 50%;
      height: 90%;
      position: absolute;
      top: 10%;
      left: 0;
      transition: margin-left .5s cubic-bezier(0.820, 0.085, 0.395, 0.895);
      background-color:#54595F;
      margin-left: 110%;
      z-index: 9;
      overflow-x: hidden;
      box-shadow: 0px 3px 12px 0px rgba(0,0,0,0.4);
    }

  .menu.is-menu-open {
      margin-left: 50%;
      
  }
  
  .icon-button{
    width:25px;
    margin-right: 7px;
  }

  .menu-page{
    width: 100%;
    height: 80%;
    overflow: hidden;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
  }
  .menu-footer{
    width: 100%;
    height: 20%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }

  .menu-footer-option{
    margin: 10px;
    font-family: Arial, Helvetica, sans-serif;
    color: white;
  }

  .menu-option{
    height: 50px;
    width: 80%;
    display: flex;
    justify-content: right;
    align-items: center;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: white;
  }

  .margin-top{
    margin-top: 40px;
  }

  .margin-left{
    margin-left: 10px;
  }

  .button-profile{
    color:white;
    background-color: #2A2C2E;
    border-radius: 3px;
    margin-bottom: 20px;
    justify-content: center;
    cursor: pointer;
    height: 40px; 
  }

  .button-profile-red{
    color:white;
    background-color: #C12335;
    border-radius: 3px;
    margin-bottom: 20px;
    justify-content: center;
    cursor: pointer;
    height: 40px; 
  }

  .menu-grey-background{
    position: absolute;
    top:10%;
    left:0;
    height:90%;
    width:100%;
    z-index: 8;
    background-color: rgb(100,100,100,0.3);
  }


.logout-popup-background{
    position: absolute;
    top: 10%;
    left:0;
    height: 90%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2A2C2E20;
    z-index: 9;
}

.logout-popup-container{
    height: 300px;
    width: 80%;
    max-width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #71757B;
    flex-direction: column;
    color:#F7F7F8;
    border-radius: 8px;
}
