.background{
  background-color: #54595F;
  height: 90%;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  color:#F7F7F8;
  text-align: center;


  overflow: scroll;
  /* Hide scrollbar for IE, Edge, and Firefox */
   -ms-overflow-style: none;  /* for Internet Explorer, Edge */
   scrollbar-width: none;  /* for Firefox */
 }

/* Hide scrollbar for Chrome, Safari and Opera */
.background::-webkit-scrollbar {
   display: none;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App{
  height:100%;
  width:100%;
  overflow: hidden;
}


.mt-20{
  margin-top: 20px;
}

.mt-10{
  margin-top: 10px;
}

/* Hide steppers for Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Hide steppers for Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}