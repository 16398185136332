/* General NavBar styling */
.navbar {
    background: #2A2C2E;
    height: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
  }

.nav-logo{
    width: auto;
    height: 100%;
    display: flex;
    justify-content:start;
    align-items: center;
    padding-left: 10px;
    color: #F7F7F8;
}

.nav-text{
    margin-left: 10px;
    font-size: 24px;
    font-weight: bold;
    height:80%;
    display: flex;
    justify-content: start;
    align-items: end;
}
  
.hamburger-menu{
    width: 50px;
    height: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    padding-right: 10px;
}

.nav-menu{
    width: auto;
    height: 100%;
    display: flex;
    justify-content:end;
    align-items: center;
    padding-right: 10px;
    color: #F7F7F8;
}

.pc-menu-option{
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

}

.pc-menu-option-red{
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #C12335;
    cursor: pointer;
}

.background-terms{
    background-color: #54595F;
    height: auto;
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    color:#F7F7F8;
    text-align: left;
    padding:10%;
    padding-top: 0;
    box-sizing: border-box;
}

.terms-popup{
    background-color: #54595F;
    position: absolute;
    top: 0;
    left:0;
    height:100%;
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    z-index: 9;
    flex-direction: column;

    overflow: scroll;
    /* Hide scrollbar for IE, Edge, and Firefox */
     -ms-overflow-style: none;  /* for Internet Explorer, Edge */
     scrollbar-width: none;  /* for Firefox */
}
  
/* Hide scrollbar for Chrome, Safari and Opera */
.terms-popup::-webkit-scrollbar {
     display: none;
}

.exit-row-terms{
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: end;
    align-items: center;
    background-color: #54595F;
 
    padding-right: 20px;
    padding-top: 10px;
    box-sizing: border-box;
}
